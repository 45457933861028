
import { Options, Vue } from 'vue-class-component';
import { PostClient } from '@/services/Services';
import Post from '@/components/post.vue';
import * as OM from '@/model';

@Options({
    components: {
        Post
    }
})
export default class CommunitiesFeed extends Vue {

    skip: number = 0;
    take: number = 0;
    results: OM.PostVm[] = [];

    finishedPosts: boolean = false;
    loading: boolean = false;

    created() {
        this.setDefaultFilter();
        this.init();
    }

    setDefaultFilter(){
        this.skip = 0;
        this.take = 10;
    }

    mounted(){
        (<any>this.$refs.scroll_section).addEventListener("scroll", detail => {
            var scrollHeight = (<any>this.$refs.scroll_section).scrollHeight;
            var scrollAndDimension = (<any>this.$refs.scroll_section).offsetHeight + (<any>this.$refs.scroll_section).scrollTop;
            var percentage = scrollAndDimension / scrollHeight * 100;

            if(percentage >= 80)
                this.init();
        });
    }

    init() {
        if(this.loading)
            return;

        if(this.finishedPosts)
            return;

        this.loading = true;

        PostClient.getAllPostsByUserCommunities(this.skip, this.take, false)
        .then(x => {
            var posts = x;

            if(posts.length == 0) {
                this.finishedPosts = true;
                return;
            }

            var tempPosts: OM.PostVm[] = [];
            
            tempPosts.push(...posts);
            if(tempPosts.length == 10){
                this.results.push(...tempPosts);
                this.skip += 10;
                return;
            }

            this.results.push(...tempPosts);
        })
        .finally( () => {
            this.loading = false;
        })
    }

    onRemoved(postIdentifier: string, index: number) {
        this.results.splice(index, 1);
    }
}

